import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";
import { buildRange, parseRange } from "./api.ranges";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */
/** @typedef {import("@microsoft/signalr").HubConnection} HubConnection */

export class ApiCustomersService {
  /**
   * Creates a new instance of the API addresses service.
   * @param {HubConnection} hub The SignalR hub connection to use.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(hub, axios) {
    this.hub   = hub;
    this.axios = axios;
  }

  /**
   * Retrieves customers.
   */
  async list(offset, limit, search, sort) {
    try {
      const params   = { search, sort };
      const range    = buildRange(offset, limit);
      const response = await this.axios.get("customers", { params, headers: { range } });

      return {
        range: parseRange(response),
        results: response.data,
      };
    } catch (error) {
      if (error.response && error.response.status === 416) {
        return {
          range: parseRange(error.response),
          results: [],
        };
      }

      notify.error("Eroare la încărcarea clienților.");

      throw newApiError(error);
    }
  }

  /**
   * Retrieves a customer's details using their phone number;
   *
   * @param {String} phone The customer's phone number.
   * @returns The customer's details, or null if not found.
   */
  async find(phone) {
    try {
      const response = await this.hub.invoke("Customers.Find", { phone });

      if (response && response.error) {
        throw newApiError(response);
      }

      return response;
    } catch (error) {
      notify.error("Eroare la încărcarea datelor clientului.");

      throw newApiError(error);
    }
  }

  /**
   * Suspends the specified customer for a limited duration.
   * @param {String} id The ID of the customer to suspend.
   * @param {String} duration The time for which to suspend the customer.
   * @param {String} reason The reason for suspension.
   */
  async suspend(id, duration, reason) {
    try {
      await this.axios.put(`customers/${encodeURIComponent(id)}/suspension`, { duration, reason });
    } catch (error) {
      notify.error("Eroare la blocarea clientului.");

      throw newApiError(error);
    }
  }

  /**
   * Cancels the specified customer's suspension.
   * @param {String} id The ID of the customer to reinstate.
   */
  async reinstate(id) {
    try {
      await this.axios.delete(`customers/${encodeURIComponent(id)}/suspension`);
    } catch (error) {
      notify.error("Eroare la deblocarea clientului.");

      throw newApiError(error);
    }
  }
}
