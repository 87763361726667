import { Subject } from "rxjs";
import notify from "@/core/services/notifications";

import { EventType, MessageType } from "./constants";
import { BriaSocket, ConnectionStatus } from "./socket";

export default class BriaService {
  /*\ ***** ***** ***** ***** ***** Constructor ***** ***** ***** ***** ***** \*/
  constructor() {
    this.events = {
      currentCalls: new Subject(),

      connected:    new Subject(),
      disconnected: new Subject(),
      reconnecting: new Subject(),
      reconnected:  new Subject(),
    };

    this._socket = new BriaSocket();

    this._socket.events.connected.subscribe(()    => this._onConnected());
    this._socket.events.disconnected.subscribe(() => this._onDisconnected());
    this._socket.events.reconnecting.subscribe(() => this._onReconnecting());
    this._socket.events.reconnected.subscribe(()  => this._onReconnected());

    this._socket.events.error.subscribe(error     => this._onError(error));
    this._socket.events.message.subscribe(message => this._onMessage(message));

    this._notification = null;
  }

  /*\ ***** ***** ***** ***** ***** Public Methods ***** ***** ***** ***** ***** \*/

  /*\ *** *** *** Connection *** *** *** \*/
  async connect() {
    if (this._socket.status === ConnectionStatus.connected) {
      // Hack that helps during development :)
      this.events.connected.next();
    } else {
      await this._socket.connect();
    }
  }

  async disconnect() {
    await this._socket.disconnect();
  }

  /*\ *** *** *** Accounts *** *** *** \*/
  /**
   * Retrieves the registered accounts.
   */
  async getAccounts() {
    const response = await this._socket.send("status", { status: { type: "account", accountType: "sip" } });

    let accounts = response.status.account;

    if (!accounts) {
      accounts = [];
    }

    if (!Array.isArray(accounts)) {
      accounts = [ accounts ];
    }

    const mapped = accounts.map(account => {
      return {
        id: `${account.type._text}/${account.accountId._text}`,
        name: account.accountName._text,
        type: account.type._text,

        enabled:    account.enabled._text === "true",
        registered: account.registered._text === "true",
      };
    });

    return mapped;
  }

  /*\ *** *** *** Calls *** *** *** \*/
  /**
   * Retrieves the current (active & incoming) calls.
   *
   * @see events.currentCalls
   */
  async getCalls() {
    const response = await this._socket.send("status", { status: { type: "call" } });

    let calls = response.status.call;

    if (!calls) {
      calls = [];
    }

    if (!Array.isArray(calls)) {
      calls = [ calls ];
    }

    const mapped = calls.map(call => {
      let participants = call.participants.participant;

      if (!Array.isArray(participants)) {
        participants = [ participants ];
      }

      return {
        id: call.id._text,

        holdStatus:      call.holdStatus._text,
        recordingStatus: call.recordingStatus._text,
        recordingFile:   call.recordingFile._text,

        participants: participants.map(participant => ({
          number:      participant.number._text,
          displayName: participant.displayName._text,

          state: participant.state._text,

          timeInitiated: parseInt(participant.timeInitiated._text, 10),
        })),
      };
    });

    return mapped;
  }

  /**
   * Answers a specific call.
   *
   * @param {String} callId The ID of the call to answer.
   */
  async answerCall(callId) {
    await this._socket.send("answer", { answerCall: { callId } });
  }

  /**
   * Holds a specific call.
   *
   * @param {String} callId The ID of the call to hold.
   */
  async holdCall(callId) {
    await this._socket.send("hold", { holdCall: { callId } });
  }

  /**
   * Resumes a specific call.
   *
   * @param {String} callId The ID of the call to resume.
   */
  async resumeCall(callId) {
    await this._socket.send("resume", { resumeCall: { callId } });
  }

  /**
   * Ends a specific call.
   *
   * @param {String} callId The ID of the call to end.
   */
  async endCall(callId) {
    await this._socket.send("endCall", { endCall: { callId } });
  }

  /**
   * Transfer the call to the specified destination.
   *
   * @param {String} callId The ID of the call to transfer.
   * @param {String} targetNumber The phone number to transfer the call to.
   */
  async transferCall(callId, targetNumber) {
    await this._socket.send("transferCall", { transferCall: { callId, targetNumber } });
  }

  /*\ ***** ***** ***** ***** ***** Private Methods ***** ***** ***** ***** ***** \*/

  /*\ *** *** *** Socket Connection Events *** *** *** \*/
  _onConnected() {
    this._clearNotification();

    this.events.connected.next();
  }

  _onDisconnected() {
    this._clearNotification();
    this._notification = notify.error("Conexiunea la telefon a fost întreruptă.", -1);

    this.events.disconnected.next();
  }

  _onReconnecting() {
    this._clearNotification();
    this._notification = notify.warning("Conexiunea la telefon a fost întreruptă.<br />Se reconectează...", -1);

    this.events.reconnecting.next();
  }

  _onReconnected() {
    this._clearNotification();
    notify.success("Conexiunea la telefon a fost restabilită.");

    this.events.reconnected.next();
  }

  /*\ *** *** *** Socket Message Events *** *** *** \*/
  _onError(error) {
    console.error("Bria socket error", error);
    notify.error("Conexiunea la telefon a îmtâmpinat o eroare.");
  }

  _onMessage(message) {
    switch (message.messageType) {
      case MessageType.Event:
        this._handleEvent(message.eventType, message.content.event);
        break;

      case MessageType.Error:
        /* Handle Error from API */
        console.error(`Bria error ${message.errorCode}: ${message.errorText}`);
        break;

      default:
        /* Unknown message type received */
        console.warn(`Bria unknown message type: ${message.messageType}`);
        break;
    }
  }

  /*\ *** *** *** Event Handling *** *** *** \*/
  _handleEvent(eventType, event) {
    switch (eventType) {
      case EventType.StatusChange:
        this._handleStatusChangeEvent(event._attributes.type);
        break;
    }
  }

  _handleStatusChangeEvent(statusType) {
    switch (statusType) {
      case "call":
        this.getCalls()
            .then(calls => this.events.currentCalls.next(calls));

        break;
    }
  }

  /*\ *** *** *** Notification *** *** *** \*/
  _clearNotification() {
    if (this._notification) {
      this._notification.close();
      this._notification = null;
    }
  }
}
