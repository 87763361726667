import api from "@/core/services/api";

export default {
  state: {
    user: null,
    loggedIn: false
  },

  getters: {
    currentUser: state => state.user,
    isLoggedIn:  state => state.loggedIn
  },

  mutations: {
    authLoading(state) {
      state.status = "loading";
    },

    authSuccess(state, user) {
      state.user     = user;
      state.loggedIn = true;
      state.status   = "success";
    },

    authError(state) {
      state.user     = null;
      state.loggedIn = false;
      state.status   = "error";
    },

    logout(state) {
      state.user     = null;
      state.loggedIn = false;
      state.status   = null;
    }
  },

  actions: {
    async login({ commit }, { username, password, remember }) {
      commit("authLoading");

      try {
        const user = await api.auth.login(username, password, remember);

        await api.connect();

        commit("authSuccess", user);
      } catch (error) {
        commit("authError", error);

        throw error;
      }
    },

    async logout({ commit }) {
      try {
        await api.auth.logout();
      } catch (error) {
        // ignored
      } finally {
        commit("logout");
      }
    },

    async checkLoggedIn({ commit }) {
      commit("authLoading");

      try {
        const user = await api.auth.getLoggedInUser();

        if (user) {
          await api.connect();

          commit("authSuccess", user);
        } else {
          commit("logout");
        }
      } catch (error) {
        commit("authError", error);
      }
    }
  }
};
