import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";

/** @typedef {import("@microsoft/signalr").HubConnection} HubConnection */

export class ApiAddressesService {
  /**
   * Creates a new instance of the API addresses service.
   * @param {HubConnection} hub The SignalR hub connection to use.
   */
  constructor(hub) {
    this.hub = hub;
  }

  /**
   * Returns the address for the specified location.
   * @param address The address to geocode.
   * @returns The geocoded location, or null if not found.
   */
  async geocode(address) {
    try {
      const response = await this.hub.invoke("Addresses.Geocode", address);

      if (response && response.error) {
        throw newApiError(response);
      }

      return response;
    } catch (error) {
      notify.error("Eroare la încărcarea adresei.");

      throw newApiError(error);
    }
  }

  /**
   * Returns the address for the specified location.
   * @param {Number} latitude The location's latitude.
   * @param {Number} longitude The location's longitude.
   */
  async reverseGeocode(latitude, longitude) {
    try {
      const response = await this.hub.invoke("Addresses.ReverseGeocode", latitude, longitude);

      if (!response) {
        return null;
      }

      if (response.error) {
        throw newApiError(response);
      }

      return response;
    } catch (error) {
      notify.error("Eroare la încărcarea adresei.");

      throw newApiError(error);
    }
  }

  /**
   * Searches for a landmark.
   * @param {String} terms The landmark search terms.
   * @param {String} zone The zone to search in.
   * @param {String} street The street to search on.
   */
   async searchLandmark(terms, zone, street) {
    try {
      const response = await this.hub.invoke("Addresses.SearchLandmark", terms, zone, street);

      if (response.error) {
        throw newApiError(response);
      }

      return response;
    } catch (error) {
      notify.error("Eroare la căutarea punctului de interes.");

      throw newApiError(error);
    }
  }

  /**
   * Searches for a zone.
   * @param {String} terms The zone search terms.
   */
  async searchZone(terms) {
    try {
      const response = await this.hub.invoke("Addresses.SearchZone", terms);

      if (response.error) {
        throw newApiError(response);
      }

      return response;
    } catch (error) {
      notify.error("Eroare la căutarea cartierului.");

      throw newApiError(error);
    }
  }

  /**
   * Searches for a street.
   * @param {String} terms The street search terms.
   */
  async searchStreet(terms) {
    try {
      const response = await this.hub.invoke("Addresses.SearchStreet", terms);

      if (response.error) {
        throw newApiError(response);
      }

      return response;
    } catch (error) {
      notify.error("Eroare la căutarea străzii.");

      throw newApiError(error);
    }
  }

  /**
   * Searches for a building.
   * @param {String} terms The building search terms.
   * @param {String} zone The zone to search in.
   * @param {String} street The street to search on.
   */
  async searchBuildingNo(terms, zone, street) {
    try {
      const response = await this.hub.invoke("Addresses.SearchBuildingNo", terms, zone, street);

      if (response.error) {
        throw newApiError(response);
      }

      return response;
    } catch (error) {
      notify.error("Eroare la căutarea blocului.");

      throw newApiError(error);
    }
  }
}
