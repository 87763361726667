import { HubConnectionBuilder, HubConnectionState, HttpTransportType } from "@microsoft/signalr";
import { Subject } from "rxjs";
import notify from "@/core/services/notifications";

const endpoint = "https://localhost:7878/";

export default class LinphoneService {
  /*\ ***** ***** ***** ***** ***** Constructor ***** ***** ***** ***** ***** \*/
  constructor() {
    const reconnectIntervals = [
      0,
      1  * 1000,
      2  * 1000,
      3  * 1000,
      5  * 1000,
      10 * 1000,
      15 * 1000,
      30 * 1000,
      45 * 1000,
      60 * 1000
    ];

    const hubOptions = {
      transport: HttpTransportType.WebSockets,
      withCredentials: false,
    };

    this.hub = new HubConnectionBuilder()
      .withUrl(endpoint + "hubs/phone", hubOptions)
      .withAutomaticReconnect(reconnectIntervals)
      .build();

    this.hub.keepAliveIntervalInMilliseconds = 2.5 * 1000;
    this.hub.serverTimeoutInMilliseconds     = 2 * this.hub.keepAliveIntervalInMilliseconds;

    this.hub.on("Calls.Incoming", (id, phone) => this.events.incoming.next({id, phone}));
    this.hub.on("Calls.Answered", (id, phone) => this.events.answered.next({id, phone}));
    this.hub.on("Calls.Held",     (id, phone) => this.events.held.next({id, phone}));
    this.hub.on("Calls.Resumed",  (id, phone) => this.events.resumed.next({id, phone}));
    this.hub.on("Calls.Ended",    (id, phone) => this.events.ended.next({id, phone}));

    this.events = {
      currentCalls: new Subject(),

      incoming: new Subject(),
      answered: new Subject(),
      held:     new Subject(),
      resumed:  new Subject(),
      ended:    new Subject(),
    };

    this.connection = {
      state: new Subject(),
      events: {
        connecting:    new Subject(),
        connected:     new Subject(),
        disconnecting: new Subject(),
        disconnected:  new Subject(),
        reconnecting:  new Subject(),
        reconnected:   new Subject(),
      },
    };

    this.connection.state.subscribe(state => {
      switch(state) {
        case HubConnectionState.Connecting:
          this.connection.events.connecting.next();
          break;

        case HubConnectionState.Connected:
          this.connection.events.connected.next();
          break;

        case HubConnectionState.Disconnected:
          this.connection.events.disconnected.next();
          break;

        case HubConnectionState.Disconnecting:
          this.connection.events.disconnecting.next();
          break;

        case HubConnectionState.Reconnecting:
          this.connection.events.reconnecting.next();
          break;
      }
    });

    var reconnectingNotification = null;

    this.hub.onreconnecting(() => {
      this.connection.state.next(this.hub.state);

      reconnectingNotification = notify.warning("Conexiunea la Linphone a fost întreruptă.<br />Se reconectează...", -1);
    });

    this.hub.onreconnected(() => {
      this.connection.state.next(this.hub.state);
      this.connection.events.reconnected.next();

      notify.success("Conexiunea la Linphone a fost restabilită.");

      if (reconnectingNotification) {
        reconnectingNotification.close();
        reconnectingNotification = null;
      }
    });

    this.hub.onclose(() => {
      this.connection.state.next(this.hub.state);

      notify.error("Conexiunea la Linphone este întreruptă.", -1);

      if (reconnectingNotification) {
        reconnectingNotification.close();
        reconnectingNotification = null;
      }
    });
  }

  /*\ ***** ***** ***** ***** ***** Public Methods ***** ***** ***** ***** ***** \*/

  /*\ *** *** *** Connection *** *** *** \*/
  async connect() {
    try {
      this.connection.state.next(HubConnectionState.Connecting);

      await this.hub.start();

      this.connection.state.next(this.hub.state);
    } catch {
      this.connection.state.next(this.hub.state);
    }
  }

  async disconnect() {
    this.connection.state.next(HubConnectionState.Disconnecting);

    await this.hub.stop();
  }

  /*\ *** *** *** Accounts *** *** *** \*/
  /**
   * Retrieves the registered accounts.
   */
   async getAccounts() {
    try {
      const response = await this.hub.invoke("Accounts.List");

      return response;
    } catch (error) {
      notify.error("Eroare la încărcarea conturilor SIP.");

      throw error;
    }
  }

  /*\ *** *** *** Calls *** *** *** \*/
  /**
   * Retrieves the current (active & incoming) calls.
   *
   * @see events.active
   */
   async getCalls() {
    try {
      const response = await this.hub.invoke("Calls.List");

      return response;
    } catch (error) {
      notify.error("Eroare la încărcarea apelurilor în curs.");

      throw error;
    }
  }

  /**
   * Answers a specific call.
   *
   * @param {String} The ID of the call to answer.
   */
  async answerCall(callId) {
    try {
      await this.hub.invoke("Calls.Answer", callId);
    } catch (error) {
      notify.error("Eroare la răspunderea apelului.");

      throw error;
    }
  }

  /**
   * Holds a specific call.
   *
   * @param {String} The ID of the call to hold.
   */
  async holdCall(callId) {
    try {
      await this.hub.invoke("Calls.Hold", callId);
    } catch (error) {
      notify.error("Eroare la punerea apelului în așteptare.");

      throw error;
    }
  }

  /**
   * Resumes a specific call.
   *
   * @param {String} The ID of the call to resume.
   */
  async resumeCall(callId) {
    try {
      await this.hub.invoke("Calls.Resume", callId);
    } catch (error) {
      notify.error("Eroare la răspunderea apelului.");

      throw error;
    }
  }

  /**
   * Ends a specific call.
   *
   * @param {String} The ID of the call to end.
   */
  async endCall(callId) {
    try {
      await this.hub.invoke("Calls.End", callId);
    } catch (error) {
      notify.error("Eroare la închiderea apelului.");

      throw error;
    }
  }

  /**
   * Transfer the call to the specified destination.
   *
   * @param {String} callId The ID of the call to transfer.
   * @param {String} targetNumber The phone number to transfer the call to.
   */
  async transferCall(callId, targetNumber) {
    try {
      await this.hub.invoke("Calls.Transfer", callId, targetNumber);
    } catch (error) {
      notify.error("Eroare la transferul apelului.");

      throw error;
    }
  }
}
