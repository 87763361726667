import { Subject } from "rxjs";
import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";
import { buildRange, parseRange } from "./api.ranges";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */
/** @typedef {import("@microsoft/signalr").HubConnection} HubConnection */

export class ApiCarsService {
  /**
   * Creates a new instance of the API cars service.
   * @param {HubConnection} hub The SignalR hub connection to use.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(hub, axios) {
    this.events = {
      active: new Subject(),
      online: new Subject(),
      moved: new Subject(),
      inOrder: new Subject(),
      joinedStation: new Subject(),
      offline: new Subject(),
    };

    this.hub = hub;
    this.hub.on("Cars.Active",        cars => this.events.active.next(cars));
    this.hub.on("Cars.Online",        (id, driver)   => this.events.online.next({ id, driver }));
    this.hub.on("Cars.Moved",         (id, lat, lng) => this.events.moved.next({ id, lat, lng }));
    this.hub.on("Cars.InOrder",       (id, order)    => this.events.inOrder.next({ id, order }));
    this.hub.on("Cars.JoinedStation", (id, station)  => this.events.joinedStation.next({ id, station }));
    this.hub.on("Cars.Offline",       id             => this.events.offline.next({ id }));

    this.axios = axios;
  }

  /**
   * Retrieves a specific car.
   */
  async load(id) {
    try {
      const response = await this.axios.get(`cars/${id}`);

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea indicativelor.");

      throw newApiError(error);
    }
  }

  /**
   * Retrieves cars.
   */
  async list(offset, limit, search, sort) {
    try {
      const params   = { search, sort };
      const range    = buildRange(offset, limit);
      const response = await this.axios.get("cars", { params, headers: { range } });

      return {
        range: parseRange(response),
        results: response.data,
      };
    } catch (error) {
      if (error.response && error.response.status === 416) {
        return {
          range: parseRange(error.response),
          results: [],
        };
      }

      notify.error("Eroare la încărcarea indicativelor.");

      throw newApiError(error);
    }
  }

  /**
   * Retrieves all online cars.
   */
  async listOnline() {
    try {
      const response = await this.hub.invoke("Cars.List");

      if (response.error) {
        throw newApiError(response);
      }

      return response;
    } catch (error) {
      notify.error("Eroare la încărcarea indicativelor conectate.");

      throw newApiError(error);
    }
  }

  /**
   * Registers for car status updates.
   */
  async register() {
    try {
      const response = await this.hub.invoke("Cars.Register");

      if (response && response.error) {
        throw newApiError(response);
      }
    } catch (error) {
      notify.error("Eroare la încărcarea indicativelor conectate.");

      throw newApiError(error);
    }
  }

  /**
   * Grants priority to the specified car's driver in a specified range and for a limited duration.
   * @param {String} carId The ID of the car to grant priority to.
   * @param {Number} radius The range radius in which the car will have priority.
   * @param {String} duration The time for which the car will have priority.
   */
  async grantRangePriority(carId, radius, duration) {
    try {
      await this.axios.post(`cars/${carId}/priority/range`, { radius, duration });
    } catch (error) {
      notify.error("Eroare la acordarea priorității.");

      throw newApiError(error);
    }
  }

  /**
   * Grants priority to the specified car's driver for a limited duration.
   * @param {String} carId The ID of the car to grant priority to.
   * @param {String} duration The time for which the car will have priority.
   */
  async grantTimePriority(carId, duration) {
    try {
      await this.axios.post(`cars/${carId}/priority/time`, { duration });
    } catch (error) {
      notify.error("Eroare la acordarea priorității.");

      throw newApiError(error);
    }
  }

  /**
   * Revokes a range priority from the specified car's driver.
   * @param {String} carId The ID of the car to revoke priority from.
   * @param {String} priorityId The ID of the priority to revoke.
   */
  async revokeRangePriority(carId, priorityId) {
    try {
      await this.axios.delete(`cars/${carId}/priority/range/${priorityId}`);
    } catch (error) {
      notify.error("Eroare la anularea priorității.");

      throw newApiError(error);
    }
  }

  /**
   * Revokes a limited duration priority from the specified car's driver.
   * @param {String} carId The ID of the car to revoke priority from.
   * @param {String} priorityId The ID of the priority to revoke.
   */
  async revokeTimePriority(carId, priorityId) {
    try {
      await this.axios.delete(`cars/${carId}/priority/time/${priorityId}`);
    } catch (error) {
      notify.error("Eroare la anularea priorității.");

      throw newApiError(error);
    }
  }

  /**
   * Revokes all priorities from the specified car's driver.
   * @param {String} carId The ID of the car to revoke priority from.
   */
  async revokeAllPriorities(carId, priorityId) {
    try {
      await this.axios.delete(`cars/${carId}/priority`);
    } catch (error) {
      notify.error("Eroare la anularea priorității.");

      throw newApiError(error);
    }
  }
}
