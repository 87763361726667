import lodash from "lodash";

export function buildRange(offset, limit) {
  if (offset && !lodash.isFinite(offset)) throw new Error("The offset must be a finite number.");
  if (offset < 0) throw new Error("The offset cannot be negative.");

  if (limit && !lodash.isFinite(limit)) throw new Error("The limit must be a finite number.");
  if (limit <= 0) throw new Error("The limit must be greater than zero.");

  offset = offset || 0;
  limit  = limit  || 25;

  const from = offset;
  const to   = offset + limit - 1;

  return `results=${from}-${to}`;
}

export function parseRange(response) {
  if (!response) throw new Error("The response cannot be null.");
  if (!response.headers) throw new Error("The response must have headers.");
  if (!lodash.isObjectLike(response.headers)) throw new Error("The response headers must be an object.");

  const value = response.headers["content-range"];
  if (!value || !lodash.isString(value)) return null;

  const matches = value.match(/^(?<unit>[a-z]+) (((?<from>\d+)-(?<to>\d+))|\*)\/((?<total>\d+)|\*)$/);
  if (!matches) return null;

  const range = {
    unit:  matches.groups.unit,
    from:  parseInt(matches.groups.from),
    to:    parseInt(matches.groups.to),
    total: parseInt(matches.groups.total),

    offset: NaN,
    limit:  NaN,
  };

  if (isFinite(range.from) && isFinite(range.to)) {
    range.offset = Math.max(0, range.from);
    range.limit  = range.to - range.from + 1;
  }

  return range;
}
