import { Subject } from "rxjs";

import { newApiError } from "./api.errors";

/** @typedef {import("@microsoft/signalr").HubConnection} HubConnection */

export class ApiRelayService {
  /**
   * Creates a new instance of the API relay service.
   *
   * @param {HubConnection} hub The SignalR hub connection to use.
   */
  constructor(hub) {
    this.events = {
      message: new Subject(),
    };

    this.hub = hub;
    this.hub.on("Relay.Message", message => this.events.message.next(message));
  }

  /**
   * Broadcasts a message to all other users.
   *
   * @param {Object} message The message to broadcast.
   */
  async broadcast(message) {
    try {
      const response = await this.hub.invoke("Relay.Broadcast", { payload: message });

      if (response && response.error) {
        throw newApiError(response);
      }
    } catch (error) {
      throw newApiError(error);
    }
  }

  /**
   * Sends a message to a specific user.
   *
   * @param {Object} recipient The recipient of the message.
   * @param {Object} message The message to broadcast.
   */
  async send(recipient, message) {
    try {
      const response = await this.hub.invoke("Relay.Send", { payload: message, recipient });

      if (response && response.error) {
        throw newApiError(response);
      }
    } catch (error) {
      throw newApiError(error);
    }
  }
}
