import { Subject } from "rxjs";
import WebSocketAsPromised from "websocket-as-promised";
import convert from "xml-js";

import { apiUrl, EventType, MessageType } from "./constants";

const reconnectIntervals = [
  0,
  1  * 1000,
  2  * 1000,
  3  * 1000,
  5  * 1000,
  10 * 1000,
  15 * 1000,
  30 * 1000,
  45 * 1000,
  60 * 1000
];

const _declaration = {
  _attributes: {
    version: "1.0",
    encoding: "utf-8",
  },
};

const userAgent = "Taxi Barby Dispatch";

export const ConnectionStatus = Object.freeze({
  connecting:    "connecting",
  connected:     "connected",
  disconnecting: "disconnecting",
  disconnected:  "disconnected",
  reconnecting:  "reconnecting",
});

export class BriaSocket {
  /*\ ***** ***** ***** ***** ***** Constructor ***** ***** ***** ***** ***** \*/
  constructor() {
    this.events = {
      connected:    new Subject(),
      disconnected: new Subject(),
      reconnecting: new Subject(),
      reconnected:  new Subject(),

      error:   new Subject(),
      message: new Subject(),
    };

    const socketOptions = {
      packMessage:   this._packMessage,
      unpackMessage: this._unpackMessage,

      attachRequestId:  this._attachRequestId,
      extractRequestId: this._extractRequestId,
    };

    this._socket = new WebSocketAsPromised(apiUrl, socketOptions);

    this._socket.onOpen.addListener(event  => this._onConnected(event));
    this._socket.onClose.addListener(event => this._onDisconnected(event));
    this._socket.onError.addListener(event => this._onError(event));

    this._socket.onUnpackedMessage.addListener(message => this._onMessage(message));

    this._nextRequestId = 1;

    this._reconnecting   = false;
    this._reconnectDelay = 0;
    this._reconnectTimer = null;
  }

  /*\ ***** ***** ***** ***** ***** Properties ***** ***** ***** ***** ***** \*/
  get status() {
    if (this._socket.isOpening) {
      if (this._reconnecting) {
        return ConnectionStatus.reconnecting;
      } else {
        return ConnectionStatus.connecting;
      }
    }

    if (this._socket.isOpened) {
      return ConnectionStatus.connected;
    }

    if (this._socket.isClosing) {
      return ConnectionStatus.disconnecting;
    }

    if (this._socket.isClosed) {
      if (this._reconnecting) {
        return ConnectionStatus.reconnecting;
      } else {
        return ConnectionStatus.disconnected;
      }
    }

    throw new Error("Invalid Bria socket status.");
  }

  /*\ ***** ***** ***** ***** ***** Public Methods ***** ***** ***** ***** ***** \*/
  async connect() {
    if (this.status == ConnectionStatus.connected)    return;
    if (this.status == ConnectionStatus.connecting)   return;
    if (this.status == ConnectionStatus.reconnecting) return;

    try {
      await this._socket.open();
    } catch(e) {
      throw e;
    }
  }

  async disconnect() {
    await this._socket.close();
  }

  async send(type, data) {
    const response = await this._socket.sendRequest({ type, data }, { requestId: this._nextRequestId++ });

    if (response.messageType === MessageType.Response) {
      return response.content;
    } else {
      return response;
    }
  }

  /*\ ***** ***** ***** ***** ***** Private Methods ***** ***** ***** ***** ***** \*/

  /*\ *** *** *** Socket Connection Events *** *** *** \*/
  _onConnected() {
    if (this._reconnecting) {
      this._reconnecting = false;
      this.events.reconnected.next();
    } else {
      this.events.connected.next();
    }

    this._resetReconnectTimer();
  }

  _onDisconnected(event) {
    switch (event.code) {
      case 1000:
      case 1001:
        this.events.disconnected.next();
        return;

      default:
        this._reconnect();
        return;
    }
  }

  _onError(event) {
    this.events.error.next(event.error);
  }

  /*\ *** *** *** Reconnection *** *** *** \*/
  _reconnect() {
    const delay = reconnectIntervals[this._reconnectDelay++];

    if (typeof(delay) === "undefined") {
      this._resetReconnectTimer();
      this._onDisconnected();

      return; // Ran out of reconnect attempts.
    }

    this._reconnecting = true;
    this.events.reconnecting.next();

    this._reconnectTimer = setTimeout(() => this._socket.open(), delay);
  }

  _resetReconnectTimer() {
    if (this._reconnectTimer) {
      clearTimeout(this._reconnectTimer);

      this._reconnectDelay = 0;
      this._reconnectTimer = null;
    }
  }

  /*\ *** *** *** Socket Message Events *** *** *** \*/
  _onMessage(message) {
    if (message.messageType !== MessageType.Response) {
      this.events.message.next(message);
    }
  }

  /*\ *** *** *** Socket Message Handling *** *** *** \*/
  _packMessage(message) {
    const content = convert.js2xml({ _declaration, ...message.data }, { compact: true });

    const headers = [
      `GET /${message.type}`,
      `User-Agent: ${userAgent}`,
      `Transaction-ID: ${message.id}`,
      'Content-Type: application/xml',
      `Content-Length: ${content.length}`
    ];

    var data = headers.join("\r\n");

    if (content.length > 0) {
      data += '\r\n\r\n' + content;
    }

    return data;
  }

  _unpackMessage(message) {
    var messageType = 0;
    var eventType   = 0;

    var errorCode = 0;
    var errorText = "";

    var transactionId   = "";
    var userAgentString = "";
    var contentType     = "";
    var contentLength   = 0;
    var content         = "";

    // Replace any Windows-Style line-endings with \n.
    var lines = message.replace( /\r\n/g, "\n").split("\n");
    var line  = lines[0];

    // Parse the first line to determine the type of message.
    if (line.substr(0,4) == 'POST') {
      messageType = MessageType.Event;

      line = line.substr(5).trim();

      if (line.substr(0,13) == '/statusChange') {
        eventType = EventType.StatusChange;
      }
    } else if (line.substr(0,8) == 'HTTP/1.1') {
      line = line.substr(8).trim();

      if (line.substr(0,6) == '200 OK') {
        messageType = MessageType.Response;
      } else if ((line[0] == '4') || (line[0] == '5')) {
        messageType = MessageType.Error;

        errorCode = line.substr(0,3);
        errorText = line.substr(4);
      }
    }

    // Parse the remaining lines in the header and extract values.
    var i = 1;

    for (; i < lines.length; i++) {
      line = lines[i];

      if (line[0] == '<') {
        // Start of the content section.
        break;
      } else if (line.substr(0,15) == 'Transaction-ID:')	{
        transactionId = line.substr(15).trim();
      } else if (line.substr(0,11) == 'User-Agent:') {
        userAgentString = line.substr(11).trim();
      } else if (line.substr(0,13) == 'Content-Type:') {
        contentType = line.substr(13).trim();
      } else if (line.substr(0,15) == 'Content-Length:') {
        contentLength = Number(line.substr(15).trim());
      } else {
        // Ignore any unknown headers.
        continue;
      }
    }

    // Re-assemble the content portion from the remaining lines.
    for (; i < lines.length; i++) {
      content += lines[i];
      if (i < lines.length-1) {
        content += '\n';
      }
    }

    // Build object with all the details from the message.
    var response = {
      messageType: messageType,
      eventType:   eventType,

      errorCode: errorCode,
      errorText: errorText,

      transactionId: transactionId,

      userAgentString: userAgentString,

      contentType:   contentType,
      contentLength: contentLength,
    };

    // Deserialize content.
    if (contentLength > 0) {
      response.content = convert.xml2js(content, { compact: true });
    }

    return response;
  }

  _attachRequestId(data, requestId) {
    return { id: requestId, ...data };
  }

  _extractRequestId(data) {
    if (data.transactionId) {
      const number = Number.parseInt(data.transactionId);

      if (Number.isInteger(number)) return number;
    }

    return null;
  }
}
