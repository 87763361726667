import { Subject } from "rxjs";
import notify from "@/core/services/notifications";

import { newApiError } from "./api.errors";

/** @typedef {import("axios").AxiosInstance} AxiosInstance */
/** @typedef {import("@microsoft/signalr").HubConnection} HubConnection */

export class ApiSettingsService {
  /**
   * Creates a new instance of the API settings service.
   * @param {HubConnection} hub The SignalR hub connection to use.
   * @param {AxiosInstance} axios The Axios instance to use.
   */
  constructor(hub, axios) {
    this.events = {
      changed: new Subject(),
    };

    this.hub = hub;

    this.hub.on("Settings.Changed", settings =>
      this.events.changed.next(settings)
    );

    this.axios = axios;
  }

  /**
   * Get settings.
   *
   * @returns settings.
   */
   async get() {
    try {
      const response = await this.axios.get("settings");

      return response.data;
    } catch (error) {
      notify.error("Eroare la încărcarea setărilor.");

      throw newApiError(error);
    }
  }
}
