
import lodash from "lodash";
import jQuery from "jquery";

const defaultSettings = {
  type: "primary",
  mouse_over: "pause",
  delay: 2000,
  animate: {
      enter: "animate__animated animate__bounceInRight",
      exit: "animate__animated animate__bounceOutRight",
  },

  onShow() {
    jQuery(this).addClass("notify");
    jQuery(this).find(".close").text("");
  },
};

function notify(message, type, delay) {
  const settings = lodash.defaults({ type, delay, timer: delay }, defaultSettings);

  return jQuery.notify({ message }, settings)
}

export default {
  info:    (message, delay) => notify(message, "info", delay),
  error:   (message, delay) => notify(message, "danger", delay),
  success: (message, delay) => notify(message, "success", delay),
  warning: (message, delay) => notify(message, "warning", delay),
};
