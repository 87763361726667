
import Deferred from "promise-deferred";
import delay from "delay";
import lodash from "lodash";
import moment from "moment";
import uuid from "uuid";

import api from "@/core/services/api";
import bria from "@/core/services/bria";
import linphone from "@/core/services/linphone";

import { toLocalPhoneNumber } from "@/core/utils";

const requestResponseTimeout = moment.duration(2, "seconds");

const callEndedRemoveDelay = moment.duration(2, "seconds");
const callTransferTimeout  = moment.duration(3, "seconds");

const cleanupInterval = moment.duration(1, "seconds");

function mapBriaCall(call) {
  const participant = call.participants[0];

  const mapped = {
    id:     call.id,
    phone:  toLocalPhoneNumber(participant.number),
  };

  if (call.holdStatus === "localHold") {
    mapped.status = "onHold";
  } else {
    if (participant.state === "ringing") {
      mapped.status = "incoming";
    } else {
      mapped.status = "active";
    }
  }

  return mapped;
}

function mapLinphoneCall(call) {
  const mapped = {
    id:     call.id,
    phone:  toLocalPhoneNumber(call.phone),
    status: call.status,
  };

  return mapped;
}

class Message {
  constructor() {
    if (new.target === Message) {
      throw new TypeError("Cannot construct Message instances directly");
    }

    this.type = this.constructor.type;
  }
}

class CallStatusRequest extends Message {
  static type = "requestCallStatus";

  constructor(phone) {
    super();

    this.id    = uuid.v4();
    this.phone = phone;
  }
}

class CallStatusResponse extends Message {
  static type = "responseCallStatus";

  constructor(request, call, user) {
    super();

    this.id   = request.id;
    this.call = call;
    this.user = user;
  }
}

class CallTransferRequest extends Message {
  static type = "requestCallTransfer";

  constructor(phone, account) {
    super();

    this.id     = uuid.v4();
    this.phone  = phone;
    this.target = account.name.replace("TB_", "").replace("1040", "");
  }
}

export default {
  namespaced: true,

  state: {
    calls: [],

    status: "disconnected",
    subscriptions: [],
    timers: [],

    pendingRequests:  [],
    pendingTransfers: [],

    softphone: "bria",
  },

  getters: {
    connected:     state => state.status === "connected",
    connecting:    state => state.status === "connecting",
    disconnected:  state => state.status === "disconnected",
    disconnecting: state => state.status === "disconnecting",

    softphone: state => state.softphone,

    anyCalls:      state => state.calls.length > 0,
    incomingCalls: state => state.calls.filter(call => call.status === "incoming"),
    activeCall:    state => state.calls.find  (call => call.status === "active"),
    heldCalls:     state => state.calls.filter(call => call.status === "onHold"),
    endedCalls:    state => state.calls.filter(call => call.status === "ended"),
  },

  mutations: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    useBria(state) {
      if (state.status !== "disconnected") {
        throw new Error(`Cannot switch softphone type while ${state.status}.`);
      }

      state.softphone = "bria";
    },

    useLinphone(state) {
      if (state.status !== "disconnected") {
        throw new Error(`Cannot switch softphone type while ${state.status}.`);
      }

      state.softphone = "linphone";
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    _connect(state, { subscriptions, timers }) {
      state.status = "connecting";

      state.subscriptions = Object.freeze(subscriptions);
      state.timers        = Object.freeze(timers);
    },

    _connected(state) {
      state.status = "connected";
    },

    _disconnect(state) {
      state.status = "disconnecting";
    },

    _disconnected(state) {
      state.status = "disconnected";

      state.calls = [];

      state.subscriptions.forEach(sub => sub.unsubscribe());
      state.subscriptions = [];

      state.timers.forEach(clearInterval);
      state.timers = [];
    },



    _newCall(state, call) {
      const existing = state.calls.find(c => c.id === call.id);

      if (!existing) {
        state.calls.push(call);
      }
    },

    _activeCall(state, id) {
      const call = state.calls.find(c => c.id === id);

      if (call) {
        call.status = "active";
      }
    },

    _heldCall(state, id) {
      const call = state.calls.find(c => c.id === id);

      if (call) {
        call.status = "onHold";
      }
    },

    _endingCall(state, id) {
      const call = state.calls.find(c => c.id === id);

      if (call) {
        call.status = "ended";
      }
    },

    _endedCall(state, id) {
      const index = state.calls.findIndex(c => c.id == id);

      if (index !== -1) {
        state.calls.splice(index, 1);
      }
    },


    _addPendingRequest(state, { request, deferred }) {
      state.pendingRequests.push({
        id: request.id,
        type: request.type,
        deferred,
        expiresAt: moment.utc().add(requestResponseTimeout),
      });
    },

    _addPendingTransfer(state, phone) {
      state.pendingTransfers.push({
        phone,
        expiresAt: moment.utc().add(callTransferTimeout),
      });
    },

    _cleanupPending(state) {
      state.pendingRequests  = state.pendingRequests.filter(request   => request.expiresAt.isAfter(moment.utc()));
      state.pendingTransfers = state.pendingTransfers.filter(transfer => transfer.expiresAt.isAfter(moment.utc()));
    },
  },

  actions: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    async connect({ commit, dispatch, state }) {
      if (state.status !== "disconnected") return;

      const subscriptions = [
        api.relay.events.message.subscribe(message => dispatch("_onRelayMessage", message)),

        bria.events.connected.subscribe(()    => dispatch("_onConnected")),
        bria.events.reconnected.subscribe(()  => dispatch("_onConnected")),
        bria.events.disconnected.subscribe(() => commit("_disconnected")),
        bria.events.currentCalls.subscribe(calls => dispatch("_onCallsChanged", calls.map(mapBriaCall))),

        linphone.connection.events.connected.subscribe(()    => dispatch("_onConnected")),
        linphone.connection.events.reconnected.subscribe(()  => dispatch("_onConnected")),
        linphone.connection.events.disconnected.subscribe(() => commit("_disconnected")),

        linphone.events.incoming.subscribe(call => dispatch("_onIncomingCall", mapLinphoneCall(call))),
        linphone.events.answered.subscribe(call => commit("_activeCall", call.id)),
        linphone.events.held.subscribe(call     => commit("_heldCall", call.id)),
        linphone.events.resumed.subscribe(call  => commit("_activeCall", call.id)),
        linphone.events.ended.subscribe(call    => dispatch("_onEndedCall", call))
      ];

      const timers = [
        setInterval(() => commit("_cleanupPending"), cleanupInterval.asMilliseconds())
      ];

      commit("_connect", { subscriptions, timers });

      if (state.softphone === "bria") {
        await bria.connect();
      } else if (state.softphone === "linphone") {
        await linphone.connect();
      } else {
        throw new Error(`Unknown softphone selected: ${state.softphone}.`);
      }
    },

    async disconnect({ commit, state }) {
      if (state.status === "connected") {
        commit("_disconnect");

        if (state.softphone === "bria") {
          await bria.disconnect();
        } else if (state.softphone === "linphone") {
          await linphone.disconnect();
        } else {
          throw new Error(`Unknown softphone selected: ${state.softphone}.`);
        }
      }
    },

    async answerCall({ state }, call) {
      if (state.softphone === "bria") {
        await bria.answerCall(call.id);
      } else if (state.softphone === "linphone") {
        await linphone.answerCall(call.id);
      } else {
        throw new Error(`Unknown softphone selected: ${state.softphone}.`);
      }
    },

    async answerCallByPhoneNumber({ dispatch, state }, payload) {
      const phone = lodash.isString(payload) ? payload : payload.phone;
      const call  = state.calls.find(c => c.phone === phone);

      if (call) {
        switch (call.status) {
          case "incoming":
            await dispatch("answerCall", call);
            return { answered: true };

          case "onHold":
            await dispatch("resumeCall", call);
            return { answered: true };

          case "active":
            return { answered: true };

          default:
            return { error: true };
        }
      } else {
        return await dispatch("_startCallTransfer", payload);
      }
    },

    async holdCall({ state }, call) {
      if (state.softphone === "bria") {
        await bria.holdCall(call.id);
      } else if (state.softphone === "linphone") {
        await linphone.holdCall(call.id);
      } else {
        throw new Error(`Unknown softphone selected: ${state.softphone}.`);
      }
    },

    async resumeCall({ state }, call) {
      if (state.softphone === "bria") {
        await bria.resumeCall(call.id);
      } else if (state.softphone === "linphone") {
        await linphone.resumeCall(call.id);
      } else {
        throw new Error(`Unknown softphone selected: ${state.softphone}.`);
      }
    },

    async transferCall({ dispatch }, payload) {
      await dispatch("_onCallTransferRequest", { payload });
    },

    async endCall({ state }, call) {
      if (state.softphone === "bria") {
        await bria.endCall(call.id);
      } else if (state.softphone === "linphone") {
        await linphone.endCall(call.id);
      } else {
        throw new Error(`Unknown softphone selected: ${state.softphone}.`);
      }
    },

    async endCallByPhoneNumber({ dispatch, state }, phone) {
      const call = state.calls.find(c => c.phone === phone);

      if (call) {
        await dispatch("endCall", call);
      }
    },

    removeEndedCall({ commit }, call) {
      if (call.status === "ended") {
        commit("_endedCall", call.id);
      }
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    async _startCallTransfer({ commit, dispatch, state }, payload) {
      const phone   = lodash.isString(payload) ? payload : payload.phone;
      const confirm = lodash.isObject(payload) ? payload.confirm : null;

      if (state.pendingTransfers.find(t => t.phone === phone)) return;

      if (confirm) {
        return await dispatch("_confirmCallTransfer", { owner: confirm.owner, phone });
      }

      const request  = new CallStatusRequest(phone);
      const deferred = new Deferred();

      commit("_addPendingRequest", { request, deferred });

      await api.relay.broadcast(request);
      return await deferred.promise;
    },

    async _confirmCallTransfer({ commit, state }, { owner, phone }) {
      if (state.pendingTransfers.find(t => t.phone === phone)) return;

      let dispatchAccount;

      if (state.softphone === "bria") {
        const accounts  = await bria.getAccounts();
        dispatchAccount = accounts.find(a => a.name.startsWith("TB_"));
      } else if (state.softphone === "linphone") {
        const accounts  = await linphone.getAccounts();
        dispatchAccount = accounts.find(a => a.type.toUpperCase() === "SIP");
      } else {
        throw new Error(`Unknown softphone selected: ${state.softphone}.`);
      }

      if (dispatchAccount) {
        const request = new CallTransferRequest(phone, dispatchAccount);

        commit("_addPendingTransfer", phone);

        await api.relay.send(owner, request);
      }
    },

    async _onConnected({ commit, dispatch, state }) {
      commit("_connected");

      let calls;

      if (state.softphone === "bria") {
        calls = await bria.getCalls();
        calls = calls.map(mapBriaCall);
      } else if (state.softphone === "linphone") {
        calls = await linphone.getCalls();
        calls = calls.map(mapLinphoneCall);
      } else {
        throw new Error(`Unknown softphone selected: ${state.softphone}.`);
      }

      await dispatch("_onCallsChanged", calls);
    },

    async _onCallsChanged({ dispatch, state }, calls) {
      const newCalls     = lodash.differenceBy(calls, state.calls, "id");
      const changedCalls = lodash.difference(lodash.differenceBy(calls, state.calls, [ "id", "status" ]), newCalls);
      const endedCalls   = lodash.differenceBy(state.calls, calls, "id");

      // console.log("Active calls changed");
      // console.log("Current calls", this.calls);
      // console.log("New calls", newCalls);
      // console.log("Changed calls", changedCalls);
      // console.log("Ended calls", endedCalls);

      for (const call of newCalls) {
        await dispatch("_onNewCall", call);
      }

      for (const call of changedCalls) {
        await dispatch("_onChangedCall", call);
      }

      for (const call of endedCalls) {
        await dispatch("_onEndedCall", call);
      }
    },

    async _onNewCall({ commit, dispatch }, call) {
      switch (call.status) {
        case "incoming":
          await dispatch("_onIncomingCall", call);
          break;

        case "ended":
          // Ignore new but ended calls (WTF?).
          break;

        default:
          commit("_newCall", call);
          break;
      }
    },

    async _onChangedCall({ commit, dispatch }, call) {
      switch (call.status) {
        case "incoming":
          await dispatch("_onIncomingCall", call);
          break;

        case "active":
          commit("_activeCall", call.id);
          break;

        case "onHold":
          commit("_heldCall", call.id);
          break;

        case "ended":
          await dispatch("_onEndedCall", call);
          break;
      }
    },

    async _onIncomingCall({ commit, dispatch, state }, call) {
      if (call.status !== "incoming") {
        call.status = "incoming";
      }

      commit("_newCall", call);

      if (state.pendingTransfers.find(t => t.phone === call.phone)) {
        await dispatch("answerCall", call);
      }
    },

    async _onEndedCall({ commit }, call) {
      commit("_endingCall", call.id);

      await delay(callEndedRemoveDelay.asMilliseconds());

      commit("_endedCall", call.id);
    },

    async _onCallStatusRequest({ state, rootGetters }, message) {
      const request = message.payload;
      const sender  = message.sender;

      const call = state.calls.find(call => call.phone === request.phone);

      if (call) {
        const response = new CallStatusResponse(request, call, rootGetters.currentUser);

        await api.relay.send(sender, response);
      }
    },

    async _onCallStatusResponse({ dispatch, state }, message) {
      const request = state.pendingRequests.find(r => r.id === message.payload.id);
      if (!request) return;

      const call = message.payload.call;
      if (!call) return;

      switch (call.status) {
        case "active":
          const result = {
            answered: false,
            dispatcher: message.payload.user,
            owner: message.sender,
          };

          request.deferred.resolve(result);
          break;

        case "onHold":
          const transfer = {
            owner: message.sender,
            phone: call.phone,
          };

          await dispatch("_confirmCallTransfer", transfer);

          request.deferred.resolve({ answered: true });
          break;
      }
    },

    async _onCallTransferRequest({ state }, message) {
      const phone  = message.payload.phone;
      const target = message.payload.target;

      const call = state.calls.find(c => c.phone === phone);

      if (call && call.status !== "ended") {
        if (state.softphone === "bria") {
          await bria.transferCall(call.id, target);
        } else if (state.softphone === "linphone") {
          await linphone.transferCall(call.id, target);
        } else {
          throw new Error(`Unknown softphone selected: ${state.softphone}.`);
        }
      }
    },

    async _onRelayMessage({ dispatch }, message) {
      if (!message.payload) return;

      switch (message.payload.type) {
        case CallStatusRequest.type:
          await dispatch("_onCallStatusRequest", message);
          break;

        case CallStatusResponse.type:
          await dispatch("_onCallStatusResponse", message);
          break;

        case CallTransferRequest.type:
          await dispatch("_onCallTransferRequest", message);
          break;
      }
    },
  },
};
