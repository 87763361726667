import lodash from "lodash";
import moment from "moment";

const localMobileNumberRegex = /^(07[0-9]{2})([0-9]{3})([0-9]{3})$/;

const localShortLandlineNumberRegex          = /^(0[23][1-9][0-9])([0-9]{3})$/;
const localBucharestShortLandlineNumberRegex = /^(0[23]1)([0-9]{4})$/;

const localLandlineNumberRegex          = /^(0[23][1-9][0-9])([0-9]{3})([0-9]{3})$/;
const localBucharestLandlineNumberRegex = /^(0[23]1)([0-9]{3})([0-9]{4})$/;

export function isNullOrEmpty(str) {
  return !str || str.length === 0;
}

export function isNullOrWhiteSpace(str) {
  return isNullOrEmpty(str) || !/\S/.test(str);
}

export function formatAddress(address, sort) {
  const addressKeys = ["street", "streetNo", "buildingNo", "blockNo", "zone"];
  const sortedKeys  = lodash.union(sort, addressKeys);

  const prefixed = lodash.mapValues(address, (value, key) => {
    switch(key) {
      case "streetNo":
        return "Nr. " + value;
      case "buildingNo":
        return "Bl. " + value;
      case "blockNo":
        return "Sc. " + value;
      case "zone":
        return "Cart. " + value;
      default:
        return value;
    }
  });

  const formatted = sortedKeys
    .filter(key => lodash.has(address, key))
    .filter(key => !isNullOrWhiteSpace(address[key]))
    .map(key => prefixed[key])
    .join(", ")
    .replace(/\s+/g, " ")
    .trim();

  return formatted;
}

export function formatPhoneNumber(phone) {
  if (localMobileNumberRegex.test(phone)) {
    return phone.replace(localMobileNumberRegex, "$1.$2.$3");
  }

  if (localShortLandlineNumberRegex.test(phone)) {
    return phone.replace(localShortLandlineNumberRegex, "$1.$2");
  }

  if (localBucharestShortLandlineNumberRegex.test(phone)) {
    return phone.replace(localBucharestShortLandlineNumberRegex, "$1.$2");
  }

  if (localLandlineNumberRegex.test(phone)) {
    return phone.replace(localLandlineNumberRegex, "$1.$2.$3");
  }

  if (localBucharestLandlineNumberRegex.test(phone)) {
    return phone.replace(localBucharestLandlineNumberRegex, "$1.$2.$3");
  }

  return phone;
}

export function toLocalPhoneNumber(phone) {
  if (phone.startsWith("00")) {
    phone = "+" + phone.substring(2);
  }

  if (phone.startsWith("+40")) {
    return phone.substring(2);
  } else {
    return phone;
  }
}

export function serializeDuration(duration) {
  if (!moment.isDuration(duration)) {
    throw new Error("'duration' must be a moment.js duration");
  }

  const milliseconds = duration.milliseconds();
  const seconds      = duration.seconds();
  const minutes      = duration.minutes();
  const hours        = duration.subtract({ milliseconds, seconds, minutes }).asHours();

  let formatted = "";

  formatted += hours.toString().padStart(2, "0");
  formatted += ":" + minutes.toString().padStart(2, "0");
  formatted += ":" + seconds.toString().padStart(2, "0");

  if (milliseconds > 0) {
    formatted += "." + milliseconds.toString().padStart(2, "0");
  }

  return formatted;
}
