export const apiUrl = "wss://cpclientapi.softphone.com:9002/counterpath/socketapi/v1/";

export const xmlDeclarationString = '<?xml version="1.0" encoding="utf-8" ?>';

export const EventType = {
  StatusChange: 1,
};

export const MessageType = {
  Response: 1,
  Event:    2,
  Error:    3,
};
